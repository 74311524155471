<template>
  <div class="page_container">
    <!-- 静态图片 -->
    <div class="about_banner">
      <img src="../../assets/image/news/news_banner.jpg" alt />
    </div>
    <!-- 新闻中心列表 -->
    <div :class="['news_box',newslist?.length >0?'news_box_margin60':'']">
      <div class="news_details clearfix">
        <!-- <div class="news_subBox left">
          <div class="news_subBox_picture" @click="to('newsDetails_new_chaotu')">
            <img src="../../assets/image/news/new_chaotu01.jpg" alt="" />
          </div>
          <div class="news_subBox_title">
            秉匠科技赴超图软件拜访交流
          </div>
          <div class="news_subBox_time">2020-11-26</div>
          <div class="news_subBox_more">
            <router-link to="/newsDetails_new_CIM"> 查看详情 >> </router-link>
          </div>
        </div>
         <div class="news_subBox left">
          <div class="news_subBox_picture" @click="to('newsDetails_new_changan')">
            <img src="../../assets/image/news/new_changan.jpg" alt="" />
          </div>
          <div class="news_subBox_title">
            国产引擎铸“大国重器”——长安大学申报的国家重点研发计划项目获批复
          </div>
          <div class="news_subBox_time">2020-11-25</div>
          <div class="news_subBox_more">
            <router-link to="/newsDetails_new_CIM"> 查看详情 >> </router-link>
          </div>
        </div>
        <div class="news_subBox left">
          <div class="news_subBox_picture" @click="to('newsDetails_new_CIM')">
            <img src="../../assets/image/news/news_cim01.jpg" alt="" />
          </div>
          <div class="news_subBox_title">
            “黑洞”图形引擎助力中电建华东院打造智慧城市CIM平台
          </div>
          <div class="news_subBox_time">2020-11-09</div>
          <div class="news_subBox_more">
            <router-link to="/newsDetails_new_CIM"> 查看详情 >> </router-link>
          </div>
        </div>
        <div class="news_subBox left">
          <div class="news_subBox_picture" @click="to('newsDetails_new_BIM')">
            <img src="../../assets/image/news/new_pudong01.jpg" alt="" />
          </div>
          <div class="news_subBox_title">
            秉匠科技又双叒叕获奖了！
          </div>
          <div class="news_subBox_time">2020-10-28</div>
          <div class="news_subBox_more">
            <router-link to="/newsDetails_new_BIM"> 查看详情 >> </router-link>
          </div>
        </div>
        <div class="news_subBox left">
          <div class="news_subBox_picture" @click="to('newsDetails_new_dongjiao')">
            <img src="../../assets/image/news/news_dongjiao01.jpg" alt="" />
          </div>
          <div class="news_subBox_title">
            江苏东交智控科技集团股份有限公司与秉匠科技签订“黑洞”图形引擎采购合同
          </div>
          <div class="news_subBox_time">2020-11-02</div>
          <div class="news_subBox_more">
            <router-link to="/newsDetails_new_dongjiao"> 查看详情 >> </router-link>
          </div>
        </div>
        <div class="news_subBox left">
          <div class="news_subBox_picture" @click="to('newsDetails_new_hj')">
            <img src="../../assets/image/news/new_hj3.jpg" alt="" />
          </div>
          <div class="news_subBox_title">
            “黑洞引擎”荣获“上海市第二届BIM技术应用创新大赛”特别创意优秀奖
          </div>
          <div class="news_subBox_time">2020-8-10</div>
          <div class="news_subBox_more">
            <router-link to="/newsDetails_new_hj"> 查看详情 >> </router-link>
          </div>
        </div>
        <div class="news_subBox left">
          <div class="news_subBox_picture" @click="to('newsDetails_new_nt')">
            <img src="../../assets/image/news/new_nt.jpg" alt="" />
          </div>
          <div class="news_subBox_title">响应国家战略，紧抓发展机遇</div>
          <div class="news_subBox_time">2020-4-26</div>
          <div class="news_subBox_more">
            <router-link to="/newsDetails_new_nt"> 查看详情 >> </router-link>
          </div>
        </div> -->
        <!-- 新闻 -->
        <div class="news_subBox left" v-for="item in newslist" :key="item.id">
          <div class="news_subBox_picture" @click="tonewsdetails(item.id)">
            <img :src="item.newsTitlePicUrl" alt="" />
          </div>
          <div class="news_subBox_title">{{item.newsTitle}}</div>
          <div class="news_subBox_time">{{item.newsTime}}</div>
          <div class="news_subBox_more">
            <p class="news_subBox_more"> <a href="javascript:;" @click="tonewsdetails(item.id)">查看详情 >> </a></p>
          </div>
        </div>
      </div>
      <div class="news_pagination">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          @current-change = "currentchange"
          hide-on-single-page
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "News",
  data() {
    return {
      total: 0,
      newslist: [],
      currentpage: 1
    };
  },
  created () {
    this.getnewslist()
    this.getnewslistpagenation(this.currentpage)
  },
  methods: {
    to (path) {
      this.$router.push(path)
    },
    async getnewslist () {
      const { data } = await this.$axios.get('news/getParams')
      // console.log(data)
      if (data.code === 200) {
        // this.newslist = data.data
        // console.log(this.newslist)
      } else {
        return this.$message.error('请求错误')
      }
    },
    async getnewslistpagenation (page) {
      const { data } = await this.$axios.get(`news/getParams/page?currentPage=${page}`)
      // console.log(data)
      if (data.code === 200) {
        // console.log(data.data)
        this.newslist = data.data.records
        this.total = data.data.total
        // console.log(this.newslist)
      } else {
        return this.$message.error('请求错误')
      }
    },
    tonewsdetails (id) {
      this.$router.push({path: '/newsDetails',query: {id}})
    },
    currentchange (page) {
      this.getnewslistpagenation(page)
    }
  }
};
</script>

<style>
</style>
